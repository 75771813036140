import React from "react";

export const Banner = () => {
  return (
    <div>
      <div className="">
        <img className="w-full h-full" src="./banner.jpg" alt=""></img>
      </div>
    </div>
  );
};

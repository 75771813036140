import React from "react";
import { YearList } from "./yearlist";

export const ProjectHistory = (props) => {
  return (
    <div className="relative">
      <div className="absolute w-[1px] h-full bg-[#C0C0C0] -top-8 left-16 z-0"></div>
      <div className="relative z-10 overflow-hidden">
        <YearList
          year="2022"
          listItem={
            props.russian
              ? [
                  "1. Строительство покрасочной камеры АО «Локомотив Курастыру Зауыты», г. Астана",
                  "2. Строительство склада АО «Локомотив Курастыру Зауыты», г.Астана",
                ]
              : [
                  "1. Construction of a spray booth of Lokomotiv Kurastyru Zauyty JSC, Astana",
                  "2. Construction of a warehouse of Lokomotiv Kurastyru Zauyty JSC, Astana",
                ]
          }
        />
        <YearList
          year="2021"
          listItem={
            props.russian
              ? [
                  "1. Строительство Бизнес-центра «Downtown», блок TOKYO (3500 кв.м.), г. Астана",
                ]
              : [
                  "1. Construction of the Business Center Downtown, TOKYO block (3500 sq.m.), Astana",
                ]
          }
        />
        <YearList
          year="2020"
          listItem={
            props.russian
              ? [
                  "1. Строительство производственной базы (1300 кв.м.), г. Астана",
                ]
              : ["1. Construction of a production base (1300 sq.m.), Astana"]
          }
        />
        <YearList
          year="2019"
          listItem={
            props.russian
              ? [
                  "1. Капитальный ремонт кровли ПВХ АО «Локомотив Курастыру   Зауыты», г. Астана",
                ]
              : [
                  "1. Major overhaul of the PVC roof of Lokomotiv Kurastyru Zauyty JSC, Astana",
                ]
          }
        />
        <YearList
          year="2018"
          listItem={
            props.russian
              ? [
                  "1. Строительство Бизнес-центра «Downtown», блок GENEVA (3500 кв.м.), г. Астана",
                  "2. Вахтовый поселок, проект МаТраГ, Проект будущего расширения, ТШО/ТОО «Тенизсервис» (субподряд), Атырауская область",
                ]
              : [
                  "1. Construction of the Business Center Downtown, block GENEVA (3500 sq.m.), Astana",
                  "2. Camp, CaTRo project, Future Growth Project, TCO/Tenizservice LLP (subcontract), Atyrau region",
                ]
          }
        />
        <YearList
          year="2017"
          listItem={
            props.russian
              ? ["1. Строительство ресторана «TOFU» (2000 кв.м.), г. Астана"]
              : ["1. Construction of the restaurant TOFU (2000 sq.m.), Astana"]
          }
        />
        <YearList
          year="2016"
          listItem={
            props.russian
              ? [
                  "1. Строительство Бизнес-центра «Downtown», блок «MANHATTAN» (3500 кв.м.), г. Астана",
                  "2. Строительство Бизнес-центра «Downtown», блок BOSTON (2500 кв.м.), г. Астана",
                  "3. Строительство Бизнес-центра «Downtown», блок DUBAI (3500 кв.м.), г. Астана",
                ]
              : [
                  "1. Construction of the Business Center Downtown, block MANHATTAN (3500 sq.m.), Astana",
                  "2. Construction of the Business Center Downtown, block BOSTON (2500 sq.m.), Astana",
                  "3. Construction of the Business Center Downtown, block DUBAI (3500 sq.m.), Astana",
                ]
          }
        />
        <YearList
          year="2015"
          listItem={
            props.russian
              ? [
                  "1. Газификация населенных пунктов, подводящих сетей высокого давления в Меркенском районе Жамбылской области. Строительные работы по прокладке местных газопроводов.",
                  "2. Реконструкция автономного теплоснабжения социальных объектов на централизованную систему теплоснабжения, г. Астана",
                  "3. Продолжение строительства Бизнес Центра -18500м2, г. Астана",
                  "4. Капитальный ремонт «Многофункционального здания кафе», СОК «Ак-Булак» Талгарского района, Алматинская область",
                  "5. Вахтовый поселок, проект прокладки местных газопроводов, Жамбылская область",
                ]
              : [
                  "1. Gasification of settlements, high-pressure supply networks in the Merken district of the Zhambyl region. Construction work on laying local gas pipelines.",
                  "2. Reconstruction of autonomous heat supply of social facilities to a centralized heat supply system, Astana",
                  "3. Continued construction of the Business Center -18500m2, Astana",
                  '4. Overhaul of the "Multifunctional cafe building", SOK "Ak-Bulak" Talgar district, Almaty region',
                  "5. Rotational camp, project for laying local gas pipelines, Zhambyl region",
                ]
          }
        />
        <YearList
          year="2014"
          listItem={
            props.russian
              ? [
                  "1.Строительство промышленной базы с БЦ  - 2500м2, г. Астана",
                  "2.Строительство Бизнес Центра -18500м2, г. Астана",
                ]
              : [
                  "1. Construction of industrial base, Astana city",
                  "2. Construction of Business Center building, Astana city",
                ]
          }
        />
        <YearList
          year="2013"
          listItem={
            props.russian
              ? [
                  "1.Строительство однотипных модульных районных ветеринарных лабораторий для Министерства сельского хозяйства РК, Акмолинская область",
                ]
              : ["1. Construction of veterinary laboratories, Akmola region"]
          }
        />
        <YearList
          year="2011"
          listItem={
            props.russian
              ? [
                  "1. Капитальный ремонт 2-х этажного административного – бытового здания Филиала «Экспресс», г. Алматы",
                  "2. Строительство пристройки к тележечному цеху, г. Балхаш, Карагандинская область",
                  "3. Строительно – монтажные работы 7-ми автозаправочных станций АО «КазМунайГаз», Алматинская область",
                  "4. Строительство однотипных модульных районных ветеринарных лабораторий для Министерства сельского хозяйства РК, Алматинская область",
                  "5. Строительство подъездного пути на станции ТЭЦ-3, , г. Астана",
                ]
              : [
                  "1. Extension construction to trucker shop, Balkhash city",
                  "2. Construction and assembly work of gas stations in Almaty region,”KAZMUNAIGAZ”",
                  "3. Construction and installation works of filling stations of KazMunayGas JSC in Almaty region",
                  "4. Construction of veterinary laboratories, Almaty region",
                  "5. Construction of rail transport, approach track TPP–3 station in Astana city",
                ]
          }
        />
        <YearList
          year="2010"
          listItem={
            props.russian
              ? [
                  "1. Капитальный ремонт резервуаров 6 группы филиала «Заря» РГП «Резерв» КГМР МЧС РК, г. Арысь, ЮКО",
                  "2. Строительство автозаправочной станции в Жамбылской области, п. Амангельды, трасса Алматы – Ташкент 528 км.",
                  "3. Капитальный ремонт перекрытия и кровли на здании резерва проводников станции Алматы – 2, г. Алматы",
                ]
              : [
                  "1. Construction of gas station in Zhambyl region, “KAZMUNAIGAZ”",
                  "2. Construction of oil storage tanks",
                ]
          }
        />
        <YearList
          year="2009"
          listItem={
            props.russian
              ? [
                  "1. Продолжение строительства 5-и этажного жилого дома ЖК «Меркур Град», мкр.Думан 2, г. Алматы",
                ]
              : ["1. Construction of 5-floored building in Almaty city"]
          }
        />
        <YearList
          year="2006"
          listItem={
            props.russian
              ? [
                  "1. Капитальный ремонт здания АТС – 2, здание АТЦ – 2 Жылыойского РУТ, г. Кульсары, Атырауская область",
                  "2. Капитальный ремонт административного здания филиала АО «Казахтелеком», г. Кызылорда",
                ]
              : [
                  "1. Administrative building construction of «Kazakhtelecom» JSC Branch, Kyzylorda city",
                ]
          }
        />
        <YearList
          year="2005"
          listItem={
            props.russian
              ? [
                  "1. Строительство административного здания, г. Кентау, ЮКО (субподряд)",
                  "2. Новое строительство сооружений телекоммуникаций, Филиал АО «Казахтелеком» г.Шымкент",
                  "3. Гостиница «Тениз» общежитного типа для проживания вахтового персонала, с.Баутино, Мангыстауская область",
                  "4. Новое строительство 3-х этажного общежития Акимата г. Кызылорда, ул. Коркыт Ата, 3",
                  "5. Строительство административного здания, ул. Лермонтова, 71, г. Астана,",
                  "6. Новое строительство СЛ КЛС на участке Абай – 15 лет Казахстана Трансформаторная подстанция(ТП) ВЛ 10, Кызылординская область.",
                ]
              : [
                  "1. Construction of administrative building, SKR, Kentau city (subcontract)",
                  "2. New construction of telecommunications facilities «Kazakhtelecom» JSC Branch, Shymkent city",
                  "3. New construction of three-floored dormitory of the Akimat of Kyzylorda city",
                  "4. Administrative building construction, Astana city",
                  "5. New construction of CL CCL on the site Abai– Kazakhstan’s 15 anniversary. Transformer substation (TS) OL 10 Kyzylorda region.",
                  '6. Hotel "Teniz" of a dormitory type for accommodation of rotational personnel, Bautino village, Mangystau region',
                ]
          }
        />
      </div>
    </div>
  );
};

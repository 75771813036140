import React from "react";

import { Banner } from "./banner";
import { CarouselImage } from "./carousel";
import { AboutSection } from "./aboutSection";
import { SectionTitle } from "./sectionTitle";
import { CareerSection } from "./careerSection";
import { PartnerSection } from "./partnerSection";
import { ContactSection } from "./contactSection";
import { ProjectHistory } from "./projectHistory";
import { WorkerInstruction } from "./workerInstruction";
import { InstructionSection } from "./instructionSection";
import { RecommendationSection } from "./recommendationSection";

export const Container = (props) => {
  return (
    <div>
      <Banner />
      <div className="bg-[#f2f2f2] px-6 md:px-6 lg:px-0">
        <div className="max-w-[1100px] mx-auto">
          <InstructionSection
            russian={props.russian}
            setRussian={props.setRussian}
          />
          <SectionTitle
            russian={props.russian}
            setRussian={props.setRussian}
            id="about"
            name={props.russian ? `О КОМПАНИИ` : `ABOUT US`}
            detail={props.russian ? `КОМПАНИЯ / КОМАНДА` : `COMPANY / TEAMS`}
          />
          <AboutSection russian={props.russian} setRussian={props.setRussian} />
          <WorkerInstruction
            russian={props.russian}
            setRussian={props.setRussian}
          />
          <SectionTitle
            russian={props.russian}
            setRussian={props.setRussian}
            id="project"
            name={props.russian ? `ПРОЕКТЫ` : `PROJECTS`}
            backtop
          />
          <ProjectHistory
            russian={props.russian}
            setRussian={props.setRussian}
          />
          <CarouselImage />
          <SectionTitle
            russian={props.russian}
            setRussian={props.setRussian}
            id="partners"
            name={props.russian ? `ПАРТНЕРЫ` : `PARTNERS`}
            backtop
          />
          <PartnerSection />
          <SectionTitle
            russian={props.russian}
            setRussian={props.setRussian}
            id="recommendation"
            name={props.russian ? `ОТЗЫВЫ` : `RECOMMENDATION`}
            backtop
          />
          <RecommendationSection />
          <SectionTitle
            russian={props.russian}
            setRussian={props.setRussian}
            id="career"
            name={props.russian ? `ВАКАНСИИ` : `CAREERS`}
            backtop
          />
          <CareerSection />
          <SectionTitle
            russian={props.russian}
            setRussian={props.setRussian}
            id="contact"
            name={props.russian ? `КОНТАКТЫ` : `CONTACT`}
            backtop
          />
          <ContactSection />
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const SectionTitle = (props) => {
  return (
    <>
      <div id={props.id} className="flex justify-between items-center py-10">
        <p className="text-[#2a8ed1] text-base md:text-2xl">{props.name}</p>
        <div className="h-[1px] w-[40%] md:w-[70%] bg-[#C0C0C0]"></div>
        {props.backtop ? (
          <div className="flex items-center">
            <a href="#top">
              <p className="text-[7px] md:text-[10px] pr-0 md:pr-2 text-[#fe5e00]">
                {props.russian ? `ВЕРНУТЬСЯ К НАЧАЛУ` : `BACK TO TOP`}
              </p>
            </a>
            <i className="fa fa-angle-up text-xs md:text-xl text-[#fe5e00]"></i>
          </div>
        ) : (
          <p className="text-[10px] md:text-sm">{props.detail}</p>
        )}
      </div>
    </>
  );
};

import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

export const RecommendationSection = () => {
  const StaticData = [
    {
      imgPath: "./recommendationimage/review1.png",
    },
    {
      imgPath: "./recommendationimage/review2.png",
    },
    {
      imgPath: "./recommendationimage/review3.png",
    },
    {
      imgPath: "./recommendationimage/review4.png",
    },
    {
      imgPath: "./recommendationimage/review5.png",
    },
    {
      imgPath: "./recommendationimage/review6.png",
    },
    {
      imgPath: "./recommendationimage/review7.png",
    },
    {
      imgPath: "./recommendationimage/review8.png",
    },
    {
      imgPath: "./recommendationimage/review9.png",
    },
    {
      imgPath: "./recommendationimage/review10.png",
    },
    {
      imgPath: "./recommendationimage/review11.png",
    },
    {
      imgPath: "./recommendationimage/review12.png",
    },
    {
      imgPath: "./recommendationimage/review13.png",
    },
    {
      imgPath: "./recommendationimage/review14.png",
    },
  ];
  const [imageCarousel, setImageCarousel] = useState(true);

  return (
    <div className="">
      {imageCarousel ? (
        <div className="relative w-full md:w-1/2 mx-auto">
          <Carousel
            autoPlay
            infiniteLoop
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            renderArrowNext={(clickHandler) => (
              <div
                className="fa fa-angle-right absolute top-1/2 -translate-y-1/2  right-0 text-5xl cursor-pointer z-50"
                onClick={clickHandler}
              ></div>
            )}
            renderArrowPrev={(clickHandler) => (
              <div
                className="fa fa-angle-left absolute top-1/2 -translate-y-1/2 left-0 text-5xl cursor-pointer z-50"
                onClick={clickHandler}
              ></div>
            )}
          >
            {StaticData.map((item, index) => (
              <div className="w-full" key={index}>
                <img className="w-full" src={item.imgPath} alt="" />
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        <div className="grid grid-cols-3 md:grid-cols-7 gap-2 md:gap-10">
          {StaticData.map((item, index) => (
            <div key={index}>
              <img className="w-36" src={item.imgPath} alt="" />
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-center gap-5 py-10">
        <i
          onClick={() => setImageCarousel(false)}
          className="fa fa-list text-3xl text-[#666] hover:text-[#fe5e00] cursor-pointer"
        ></i>
        <i
          onClick={() => setImageCarousel(true)}
          className="fa fa-th text-3xl text-[#666] hover:text-[#fe5e00] cursor-pointer"
        ></i>
      </div>
    </div>
  );
};

import React from "react";

export const Footer = () => {
  return (
    <div className=" bg-[#fff] py-2">
      <div className="max-w-[1100px] mx-auto flex justify-between items-center px-4 md:px-0">
        <div className="flex text-xs text-[#333]">
          <p>©</p>
          <p>2015 Zhumys Construction, LLC.</p>
        </div>
        <div className="text-xl text-[#666] flex gap-3 ">
          <i className="fa fa-facebook-square hover:text-[#fe5e00] cursor-pointer"></i>
          <i className="fa fa-twitter-square hover:text-[#fe5e00] cursor-pointer"></i>
          <i className="fa fa-pinterest-square hover:text-[#fe5e00] cursor-pointer"></i>
          <i className="fa fa-linkedin-square hover:text-[#fe5e00] cursor-pointer"></i>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const ContactSection = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between pb-10 text-sm">
      <div className="flex flex-col gap-5 pb-5 md:pb-0">
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="w-32 text-[#333]">Your Name:</div>
          <div>
            <input
              className="py-1 px-2 w-64 border border-[#777]"
              type="text"
              name=""
              id=""
              placeholder="Your Name"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="w-32 text-[#333]">Email:</div>
          <div>
            <input
              className="py-1 px-2 w-64 border border-[#777]"
              type="text"
              name=""
              id=""
              placeholder="Email"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="w-32 text-[#333]">Phone:</div>
          <div>
            <input
              className="py-1 px-2 w-64 border border-[#777]"
              type="text"
              name=""
              id=""
              placeholder="Phone"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="w-32 text-[#333]">Message:</div>
          <textarea
            className="border border-[#777] px-2 py-3"
            name=""
            id=""
            cols="35"
            rows="5"
            placeholder="Message"
          ></textarea>
        </div>
        <div className="flex flex-col w-36 md:ml-32 gap-5 text-[#fff]">
          <button className="py-2 px-4 bg-[#909090] hover:bg-[#fe5e00] rounded-md">
            Submit
          </button>
          <button className="py-2 px-4 bg-[#909090] hover:bg-[#fe5e00] rounded-md">
            Send
          </button>
        </div>
      </div>
      <div className="flex flex-col items-end gap-3">
        <p>ZHUMYS LTD</p>
        <p>5, Sozak Str, Astana, Kazakhstan</p>
        <p>
          <span className="text-[#fe5e00]">T:</span> +7 7172 490627
        </p>
        <p>
          <span className="text-[#fe5e00]">F:</span> +7 701 8076748
        </p>
        <p className="text-[#fe5e00]">info@zhumys.com</p>
        <div className="flex gap-4 items-centerw">
          <p>Directions </p>
          <i className="fa fa-map-pin text-[#fe5e00] text-xl"></i>
        </div>
        <div className="w-48">
          <img src="./hat.png" alt="" />
        </div>
      </div>
    </div>
  );
};

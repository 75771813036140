import React, { useState } from "react";
import classNames from "classnames";

export const Header = (props) => {
  const [sideBar, setSideBar] = useState(false);
  return (
    <div className="relative flex justify-start md:justify-center py-5 items-center text-[#666] text-sm">
      <div className="block md:hidden absolute right-0 pr-6">
        <i
          onClick={() => setSideBar(!sideBar)}
          className="fa fa-bars text-lg"
        ></i>
      </div>
      {sideBar && (
        <div
          onClick={() => setSideBar(false)}
          className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)] z-[99]"
        ></div>
      )}
      <div
        className={classNames(
          "fixed top-0 h-[120vh] w-3/4 bg-white z-[100] p-6 transition-all",
          sideBar ? "left-0" : "left-[-100vw]"
        )}
      >
        {sideBar && (
          <div>
            <div>
              <div className="flex justify-between items-center">
                <div className="md:w-56 w-36">
                  <img src="./logo.png" alt="" />
                </div>
                <div className="flex gap-2">
                  <div
                    onClick={() => props.setRussian(false)}
                    className="hover"
                  >
                    EN
                  </div>
                  |
                  <div onClick={() => props.setRussian(true)} className="hover">
                    RU
                  </div>
                </div>
              </div>
              <div className="pt-8 gap-6 flex flex-col">
                <div className="hover">
                  <a onClick={() => setSideBar(false)} href="#about">
                    {props.russian ? `О КОМПАНИИ` : `ABOUT`}
                  </a>
                </div>
                <div className="hover">
                  <a onClick={() => setSideBar(false)} href="#project">
                    {props.russian ? `ПРОЕКТЫ` : `PROJECT`}
                  </a>
                </div>
                <div className="hover">
                  <a onClick={() => setSideBar(false)} href="#partners">
                    {props.russian ? `ПАРТНЕРЫ` : `PARTNERS`}
                  </a>
                </div>
                <div className="hover">
                  <a onClick={() => setSideBar(false)} href="#recommendation">
                    {props.russian ? `ОТЗЫВЫ` : `REFERENCES`}
                  </a>
                </div>
                <div className="hover">
                  <a onClick={() => setSideBar(false)} href="#career">
                    {props.russian ? `ВАКАНСИИ` : `CAREERS`}
                  </a>
                </div>
                <div className="hover">
                  <a onClick={() => setSideBar(false)} href="#contact">
                    {props.russian ? `КОНТАКТЫ` : `CONTACT`}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="md:w-56 w-36 pl-6 md:pl-0">
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="/">
          <img className="cursor-pointer" src="./logo.png" alt="" />
        </a>
      </div>
      <div className=" gap-8 px-20 hidden md:flex">
        <div className="hover">
          <a onClick={() => setSideBar(false)} href="#about">
            {props.russian ? `О КОМПАНИИ` : `ABOUT`}
          </a>
        </div>
        <div className="hover">
          <a onClick={() => setSideBar(false)} href="#project">
            {props.russian ? `ПРОЕКТЫ` : `PROJECT`}
          </a>
        </div>
        <div className="hover">
          <a onClick={() => setSideBar(false)} href="#partners">
            {props.russian ? `ПАРТНЕРЫ` : `PARTNERS`}
          </a>
        </div>
        <div className="hover">
          <a onClick={() => setSideBar(false)} href="#recommendation">
            {props.russian ? `ОТЗЫВЫ` : `REFERENCES`}
          </a>
        </div>
        <div className="hover">
          <a onClick={() => setSideBar(false)} href="#career">
            {props.russian ? `ВАКАНСИИ` : `CAREERS`}
          </a>
        </div>
        <div className="hover">
          <a onClick={() => setSideBar(false)} href="#contact">
            {props.russian ? `КОНТАКТЫ` : `CONTACT`}
          </a>
        </div>
      </div>
      <div className="hidden md:flex gap-2">
        <div onClick={() => props.setRussian(false)} className="hover">
          EN
        </div>
        |
        <div onClick={() => props.setRussian(true)} className="hover">
          RU
        </div>
      </div>
    </div>
  );
};

import React from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export const PartnerSection = () => {
  const animate1 = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, scale: 1, x: -30, transition: { duration: 0.5 } },
  };
  const animate2 = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, scale: 1, x: 30, transition: { duration: 0.5 } },
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className="flex flex-col md:flex-row gap-10 justify-center mx-0 md:mx-20">
      <motion.div
        ref={ref}
        variants={animate1}
        initial="hidden"
        animate={control}
        className="flex gap-5"
      >
        <div className="cursor-pointer">
          <img
            className="grayscale hover:grayscale-0"
            src="./partnerlogo/parners_logo_01.png"
            alt=""
          />
        </div>
        <div className="cursor-pointer">
          <img
            className="grayscale hover:grayscale-0"
            src="./partnerlogo/parners_logo_02.png"
            alt=""
          />
        </div>
        <div className="cursor-pointer">
          <img
            className="grayscale hover:grayscale-0"
            src="./partnerlogo/parners_logo_03.png"
            alt=""
          />
        </div>
      </motion.div>
      <motion.div
        ref={ref}
        variants={animate2}
        initial="hidden"
        animate={control}
        className="flex gap-5 h-20"
      >
        <div className=" cursor-pointer">
          <img
            className="grayscale hover:grayscale-0"
            src="./partnerlogo/parners_logo_04.png"
            alt=""
          />
        </div>
        <div className="cursor-pointer">
          <img
            className="grayscale hover:grayscale-0"
            src="./partnerlogo/parners_logo_05.png"
            alt=""
          />
        </div>
      </motion.div>
    </div>
  );
};

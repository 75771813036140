import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const MotionComponent = (props) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <motion.div
      ref={ref}
      variants={props.variants}
      animate={control}
      initial="hidden"
    >
      {props.children}
    </motion.div>
  );
};

export const AboutSection = (props) => {
  const StaticData = [
    {
      imgPath: "./certificate/certificate_1.png",
    },
    {
      imgPath: "./certificate/certificate_2.png",
    },
    {
      imgPath: "./certificate/certificate_3.png",
    },
    {
      imgPath: "./certificate/certificate_4.png",
    },
  ];

  const [imageCarousel, setImageCarousel] = useState(false);

  return (
    <div>
      <div className="grid grid-cols-6">
        <div className="col-span-4 md:col-span-1">
          <img src="./flag.png" alt="" />
        </div>
        <div className="col-span-6 md:col-span-5 pl-4 text-justify font-roboto text-base text-[#333] leading-6 font-light">
          <MotionComponent
            variants={{
              visible: {
                opacity: 1,
                scale: 1,
                y: 0,
                transition: { duration: 0.7 },
              },
              hidden: {
                opacity: 0,
                scale: 1,
                y: -40,
                transition: { duration: 0.5 },
              },
            }}
          >
            <div>
              {props.russian
                ? `Товарищество с ограниченной ответственностью «ЖҰМЫС» осуществляет свою деятельность в архитектурной, градостроительной и 
                строительной сфере с 2003 года. Объем работ, выполненных в течении нескольких последних лет, включает в себя проекты для таких 
                компаний как АО «Казахтелеком», АО «Пассажирские перевозки», АО «КазМүнайГаз Өнімдері», АО Проекты «Казтеміртранс», АО «КазМунайГаз – 
                Переработка и Маркетинг», ГУ Комитет государственной ветеринарной инспекции в агропромышленном комплексе Министерства сельского хозяйства 
                РК, РГП Резерв КГМР МЧС РК и многие другие.`
                : `«Zhumys» LLP carries out the activity in architectural, town-planning and construction sphere since 
               2003. The scope of work, performed during the last few years, with companies such as: «Kazakhtelecom» JSC, «Passazhirskiye 
               perevozki» JSC, «KasMunaiGas Onimderi» JSC, «KazTemirTrans» JSC, «KazMunaiGaz – Processing and Marketing» JSC, «Committee of the 
               State Inspectorate in Agroindustrial complex of the Ministry of Agriculture of RoK», CSMR of MES RК and Reserve RSE and many others.`}
            </div>
          </MotionComponent>
        </div>
      </div>
      <div>
        <MotionComponent
          variants={{
            visible: {
              opacity: 1,
              scale: 1,
              y: 0,
              transition: { duration: 0.7 },
            },
            hidden: {
              opacity: 0,
              scale: 1,
              y: 40,
              transition: { duration: 0.5 },
            },
          }}
        >
          <div className="font-roboto text-base text-[#333] leading-6 font-light py-10">
            {props.russian
              ? `Компания оснащена строительной техникой, имеет необходимую производственную 
               базу и складские помещения, укомплектованa опытным инженерно-техническим и 
               рабочим персоналом. Коллектив мобилен, способен в короткий срок и качественно 
               выполнить любые поставленные задачи.`
              : `The Company owns modern construction machinery, has a necessary
               production base and warehouses, and employs experienced engineering
               and technical staff and workers. The working team of the Company is
               very mobile and can fulfill any tasks in a short time and with high
               quality.`}
          </div>
        </MotionComponent>
        <MotionComponent
          variants={{
            visible: {
              opacity: 1,
              scale: 1,
              y: 0,
              transition: { duration: 0.7 },
            },
            hidden: {
              opacity: 0,
              scale: 1,
              y: 20,
              transition: { duration: 0.5 },
            },
          }}
        >
          <div className="font-roboto text-base text-[#333] leading-6 font-light text-center pb-10">
            {props.russian
              ? `Квалификация и профессионализм нашей компании подтверждены соответствующими
               государственными лицензиями 1 категории и сертификатами соответствия.`
              : `Qualification and professionalism of our company are supported by
               appropriate state licenses and certificates of compliance.`}
          </div>
        </MotionComponent>
        {imageCarousel ? (
          <div className="relative w-full md:w-1/2 mx-auto">
            <Carousel
              autoPlay
              infiniteLoop
              transitionTime={800}
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
              renderArrowPrev={(clickHandler) => (
                <div
                  className="fa fa-angle-left absolute top-1/2 -translate-y-1/2 -left-0 text-5xl cursor-pointer z-50 text-[#999] hover:text-[#fe5e00]"
                  onClick={clickHandler}
                ></div>
              )}
              renderArrowNext={(clickHandler) => (
                <div
                  className="fa fa-angle-right absolute top-1/2 -translate-y-1/2 right-0 text-5xl cursor-pointer z-50 text-[#999] hover:text-[#fe5e00]"
                  onClick={clickHandler}
                ></div>
              )}
            >
              {StaticData.map((item, index) => (
                <div className="h-full w-[90%] md:w-full mx-auto" key={index}>
                  <img
                    className="w-full h-full object-cover"
                    src={item.imgPath}
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <div className="flex justify-center gap-3 md:gap-10">
            {StaticData.map((item, index) => (
              <div key={index}>
                <img className="w-36" src={item.imgPath} alt="" />
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-center gap-5 py-10">
          <i
            onClick={() => setImageCarousel(false)}
            className="fa fa-list text-3xl text-[#666] hover:text-[#fe5e00] cursor-pointer"
          ></i>
          <i
            onClick={() => setImageCarousel(true)}
            className="fa fa-th text-3xl text-[#666] hover:text-[#fe5e00] cursor-pointer"
          ></i>
        </div>
      </div>
    </div>
  );
};

// export default function App() {
//   return (
//     <div className="App">
//       <Box num={1} />
//       <Box num={2} />
//       <Box num={3} />
//     </div>
//   );
// }

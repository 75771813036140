import React, { useState } from "react";
import "./App.css";

import { Header } from "./components/header";
import { Container } from "./components/container";
import { Footer } from "./components/footer";

function App() {
  const [russian, setRussian] = useState(false);

  return (
    <div>
      <Header russian={russian} setRussian={setRussian} />
      <Container russian={russian} setRussian={setRussian} />
      <Footer />
    </div>
  );
}

export default App;

import React from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export const YearList = (props) => {
  const animate1 = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, scale: 1, x: 60, transition: { duration: 0.5 } },
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <motion.div
      ref={ref}
      variants={animate1}
      initial="hidden"
      animate={control}
    >
      <div className="flex items-center">
        <p className="text-xs text-[#fe5e00] font-semibold pr-8">
          {props.year}
        </p>
        <div className="w-3 h-3 rounded-[50%] bg-[#fe5e00]"></div>
        <div className="h-[1px] w-full bg-[#b9b9b9]"></div>
      </div>
      <ul className="text-xs text-[#333] py-5 ml-20 md:ml-32 leading-6">
        {props.listItem.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </motion.div>
  );
};

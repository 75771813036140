import React from "react";

export const CareerSection = () => {
  return (
    <div>
      <p className="text-[18px] text-[#333] font-semibold">
        Менеджер по гос.закупкам
      </p>
      <div>
        <p className="text-sm text-[#333] py-3">Обязанности:</p>
        <div className="text-xs text-[#333] py-3 leading-6">
          <div>
            {">"} Мероприятия по сбору и получению информации об условиях
            готовящихся государственных и частных конкурсах;
          </div>
          <div>
            {">"} Анализ условий конкурсов и составление на основе требуемых
            закупок (поставка товаров, выполнение работ, оказание услуг) списка
            предполагаемых субподрядчиков и соисполнителей работ и услуг;
          </div>
          <div>
            {">"} Ведение переговоров с заказчиками - контрагентами
            (коммерческими организациями, государственными учреждениями и
            органами) по вопросам проводимых конкурсов и государственных
            закупок;
          </div>
          <div>
            {">"} Подготовка и оформление конкурсных заявок с учетом всех
            требований действующего законодательства о государственных закупках,
            в том числе субподрядных организаций и соисполнителей работ и услуг
            соответственно;
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm text-[#333] py-3">Требования:</p>
        <div className="text-xs text-[#333] py-3 leading-6">
          <div>{">"} Высшее образование (юридическое или экономическое);</div>
          <div>{">"} Опыт работы на данной позиции не менее трех лет;</div>
          <div>
            {">"} Знание законодательной базы в сфере гос. закупок (Правила
            госзакупок, Правила закупок АО "Самрук-Казына");
          </div>
          <div>
            {">"} Способность быстро обрабатывать информацию и вести несколько
            проектов одновременно;
          </div>
          <div>
            {">"} Знание офисной техники, офисных программ: таблицы;
            аналитический склад ума.
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm text-[#333] py-3">Условия:</p>
        <div className="text-xs text-[#333] py-3 leading-6">
          <div>{">"} Достойная заработная плата;</div>
          <div>{">"} Премирование по результатам;</div>
          <div>{">"} Оформление по ТК РК;</div>
          <div>{">"} Возможность развития.</div>
        </div>
      </div>
      <p className="text-[18px] text-[#333] font-semibold">Инженер ПТО</p>
      <div>
        <p className="text-sm text-[#333] py-3">Обязанности:</p>
        <div className="text-xs text-[#333] py-3 leading-6">
          <div>
            {">"} Разработка графиков строительного производства, ППР и другие
            технические документации (Акты на скрытые работы, Акты выполненных
            работ Ф-2 и т.д.);
          </div>
          <div>
            {">"} Проверка и согласование расчетов удорожания работ
            субподрядчиков;
          </div>
          <div>
            {">"} Контроль движения проектно-сметной документации, выдача ее
            производителям работ и субподрядчикам;
          </div>
          <div>
            {">"} Учет выполненных работ субподрядчиков, проверка и оформление
            актов выполненных работ;
          </div>
          <div>
            {">"} Осуществление контроля за соответствием списания материалов по
            проекту и нормам расхода;
          </div>
          <div>
            {">"} Сбор технической документации: выносы основного и
            вспомогательного репера, посадки здания, разбивка осей фундаментов,
            на скрытые работы, просветки ванной сварки, исполнительные съемки по
            этажам и инженерным сетям и их профиля, сертификаты на основные
            стройматериалы.
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm text-[#333] py-3">Требования:</p>
        <div className="text-xs text-[#333] py-3 leading-6">
          <div>
            {">"} Опыт работы в сфере строительства от 5 лет, а именно инженером
            ПТО от 3 лет;
          </div>
          <div>
            {">"} Знание и умение работ программными комплексами - ABC, AutoCad,
            Excel, чтение ПСД;
          </div>
          <div>{">"} Контроль в составлении актов на скрытые работы.</div>
        </div>
      </div>
      <div>
        <p className="text-sm text-[#333] py-3">Условия:</p>
        <div className="text-xs text-[#333] py-3 leading-6">
          <div>{">"} Достойная заработная плата;</div>
          <div>{">"} Оформление по ТК РК;</div>
          <div>{">"} Возможность развития.</div>
        </div>
      </div>
      <div className="flex item">
        <div className="w-60">
          <img src="./persons/botaguz.png" alt="" />
        </div>
        <div className="pt-2 text-sm text-[#333] leading-6">
          <p>HR Manager</p>
          <p>Botakoz Imambayeva</p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="text-[#fe5e00]">
            botakoz@zhumys.com
          </a>
          <p>
            Oфис: <span className="text-[#fe5e00]">+77172 490627</span>
          </p>
          <p>
            Мобильные:
            <span className="text-[#fe5e00]">+77018076722</span>
          </p>
        </div>
      </div>
    </div>
  );
};

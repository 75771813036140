import React from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const MotionComponent = (props) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <motion.div
      ref={ref}
      variants={props.variants}
      animate={control}
      initial="hidden"
    >
      {props.children}
    </motion.div>
  );
};

export const InstructionSection = (props) => {
  return (
    <div>
      <MotionComponent
        variants={{
          visible: {
            opacity: 1,
            scale: 1,
            y: 0,
            transition: { duration: 0.7 },
          },
          hidden: {
            opacity: 0,
            scale: 1,
            y: -40,
            transition: { duration: 0.5 },
          },
        }}
      >
        <div className="text-[#909090] py-8 text-justify text-sm font-roboto font-light leading-7 overflow-hidden">
          {props.russian
            ? `МЫ ДОСТОЙНО ГОРДИМСЯ КОГДА НАШИ КЛИЕНТЫ В ВОСТОРГЕ ОТ НАШИХ УСЛУГ И СОТРУДНИЧЕСТВА 
          С НАМИ. НАША КОМПАНИЯ ИМЕЯ ОПЫТ, ЗНАНИЯ, КОМАНДУ ПРОФЕССИОНАЛОВ И ПАРК СОБСТВЕННОЙ ТЕХНИКИ, 
          СТАВИТ ПЕРЕД СОБОЙ ЗАДАЧУ: РЕАЛИЗАЦИЯ ВЫСОКОКАЧЕСТВЕННЫХ ПРОЕКТОВ ДАЖЕ В СЛОЖНЫХ РЫНОЧНЫХ УСЛОВИЯХ. 
          НАДЕЖНОСТЬ, ДОБРОПОРЯДОЧНОСТЬ И СТРОГОЕ СОБЛЮДЕНИЕ СРОКОВ СДАЧИ ОБЪЕКТОВ - ГЛАВНЫЕ КАЧЕСТВА, ПОЗВОЛЯЮЩИЕ 
          НАМ ВЫПОЛНЯТЬ СВОЮ МИССИЮ`
            : `WE ARE PROUD TO SAY OUR CUSTOMERS ARE DELIGHTED WITH OUR SERVICES AND
        COOPERATION. OUR COMPANY HAS THE EXPERIENCE AND KNOWLEDGE OF A
        PROFESSIONAL TEAM, AS WELL AS THE MACHINERY FOR THE IMPLEMENTATION OF
        HIGH QUALITY PROJECTS, EVEN IN DIFFICULT MARKET CONDITIONS. RELIABILITY,
        INTEGRITY, AND STRICT ADHERENCE TO THE DELIVERY OF PROJECTS ON TIME ARE
        THE MAIN QUALITIES THAT ENABLE US TO FULFILL THIS MISSION.`}
        </div>
      </MotionComponent>

      <div className="flex flex-col md:flex-row md:justify-between text-[#555] text-sm">
        <div className="w-full md:w-1/2">
          <MotionComponent
            variants={{
              visible: {
                opacity: 1,
                scale: 1,
                x: 0,
                transition: { duration: 0.7 },
              },
              hidden: {
                opacity: 0,
                scale: 1,
                x: -20,
                transition: { duration: 0.5 },
              },
            }}
          >
            <div>
              <h3 className="text-[#fe5e00] text-lg pb-4">
                {props.russian ? `ПРЕИМУЩЕСТВА:` : `ADVANTAGES OF ZHUMYS LTD:`}
              </h3>
              <div className="font-light leading-6 font-roboto">
                <div>
                  <span className="text-[#fe5e00]">{">"}</span>
                  {props.russian
                    ? `Соответствие строительства всем технологическим требованиям`
                    : `Building compliance with all tech requirements`}
                </div>
                <div>
                  <span className="text-[#fe5e00]">{">"}</span>
                  {props.russian
                    ? `Высокая оперативность принятия решений`
                    : `High efficiency of decision-making`}
                </div>
                <div>
                  <span className="text-[#fe5e00]">{">"}</span>
                  {props.russian
                    ? `Строгое соблюдение поручений заказчика`
                    : `Strict adherence to the customer orders`}
                </div>
                <div>
                  <span className="text-[#fe5e00]">{">"}</span>
                  {props.russian
                    ? `Наличие положительных отзывов довольных клиентов`
                    : `Positive reviews of the satisfied clients`}
                </div>
                <div>
                  <span className="text-[#fe5e00]">{">"}</span>{" "}
                  {props.russian
                    ? `Гибкие условия оплаты`
                    : `Flexible payment conditions`}
                </div>
              </div>
            </div>
          </MotionComponent>
        </div>
        <div className="w-[1px] h-40 bg-[#bbb] mx-10 mt-2 hidden md:block"></div>
        <div className="w-full h-[1px] bg-[#bbb] my-4 block md:hidden"></div>
        <div className="w-full md:w-1/2">
          <MotionComponent
            variants={{
              visible: {
                opacity: 1,
                scale: 1,
                x: 0,
                transition: { duration: 0.7 },
              },
              hidden: {
                opacity: 0,
                scale: 1,
                x: 20,
                transition: { duration: 0.5 },
              },
            }}
          >
            <div>
              <h3 className="text-[#fe5e00] text-lg pb-4">
                {props.russian
                  ? `ГАРАНТИРУЕТ СВОИМ КЛИЕНТАМ:`
                  : `GUARANTEE OF ZHUMYS LTD:`}
              </h3>
              <div className="font-light text-justify leading-6 font-roboto">
                <div>
                  1.{" "}
                  {props.russian
                    ? `Соблюдение оговоренных сроков строительства, благодаря большому количеству ранее сданных объектов`
                    : `Compliance with the agreed dates of building due to the large
              quantity of commissioned projects.`}
                </div>
                <div>
                  2.{" "}
                  {props.russian
                    ? `Работа в рамках утвержденной сметы, благодаря наличию собственной техники, оборудования и опытного персонала`
                    : `Work under the approved budget, by providing machinery,
              equipment, and experienced personnel.`}
                </div>
              </div>
            </div>
          </MotionComponent>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const WorkerInstruction = (props) => {
  const Line = (props) => (
    <div className="flex justify-between items-center">
      <div className="h-[1px] w-[32%] md:w-[38%] bg-[#b9b9b9]"></div>
      <p className="text-[9px] md:text-sm">{props.name}</p>
      <div className="h-[1px] w-[32%] md:w-[38%] bg-[#b9b9b9]"></div>
    </div>
  );
  const Image = (props) => (
    <div>
      <img className="w-full h-[220px] object-cover" src={props.src} alt="" />
      <div className="pt-3 leading-5 text-xs text-center">
        <p>{props.office}</p>
        <p>{props.name}</p>
      </div>
    </div>
  );
  return (
    <div>
      <Line name={props.russian ? `ОСНОВАТЕЛИ` : `FOUNDER`} />
      <div className="py-5 grid grid-cols-2 md:grid-cols-5 gap-6">
        <Image
          office={props.russian ? `Основатель компании` : `Founder`}
          name={props.russian ? `Кажигалиев Биржан` : `Birzhan Kazhigaliyev`}
          src="./persons/birzhan.jpg"
        />
      </div>
      <Line name={props.russian ? `СОВЕТ ДИРЕКТОРОВ` : `BOARD MEMBER`} />
      <div className="py-5 grid grid-cols-2 md:grid-cols-5 gap-6">
        <Image
          office={
            props.russian ? `Руководитель компании` : `Head of Construction`
          }
          name={props.russian ? `Тлеуленов Ринат` : `Rinat Tleulenov`}
          src="./persons/rinat.jpeg"
        />
        <Image
          office={props.russian ? `Руководитель проекта` : `Project Manager`}
          name={props.russian ? `Тлеугалиев Бейбарыс` : `Beibaris Tleugaliev`}
          src="./persons/beybaris.jpeg"
        />
        <Image
          office={props.russian ? `Главный учетчик` : `Chief Estinate Engineer`}
          name={props.russian ? `Кабыкенов Рамазан` : `Ramazan Kabikenov`}
          src="./persons/ramazan.jpeg"
        />
        <Image
          office={props.russian ? `Мастер` : `Construction Superviser`}
          name={props.russian ? `Табанбаев Сапаргали` : `Sapargali Tabanbaev`}
          src="./persons/sapargali.jpeg"
        />
      </div>
    </div>
  );
};

import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const StaticData = [
  {
    imgPath: "./carousel/last1.jpg",
  },
  {
    imgPath: "./carousel/last2.jpeg",
  },
  {
    imgPath: "./carousel/last3.jpeg",
  },
  {
    imgPath: "./carousel/last4.jpeg",
  },
  {
    imgPath: "./carousel/last5.jpg",
  },
  {
    imgPath: "./carousel/last6.jpeg",
  },
  {
    imgPath: "./carousel/last7.jpeg",
  },
  {
    imgPath: "./carousel/last8.jpeg",
  },
  {
    imgPath: "./new1.jpg",
  },
  {
    imgPath: "./new2.jpg",
  },
  {
    imgPath: "./new3.jpg",
  },
  {
    imgPath: "./new4.jpg",
  },
  {
    imgPath: "./new5.jpg",
  },
  {
    imgPath: "./new6.jpg",
  },
  {
    imgPath: "./new7.jpg",
  },
  {
    imgPath: "./new8.jpg",
  },
  {
    imgPath: "./new9.jpg",
  },
];

export const CarouselImage = () => {
  return (
    <div className="relative w-full md:w-2/3 mx-[auto] pt-8">
      <Carousel
        autoPlay
        infiniteLoop
        transitionTime={800}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(clickHandler) => (
          <div
            className="fa fa-angle-left absolute top-1/2 -translate-y-1/2 -left-0 text-5xl cursor-pointer z-50"
            onClick={clickHandler}
          ></div>
        )}
        renderArrowNext={(clickHandler) => (
          <div
            className="fa fa-angle-right absolute top-1/2 -translate-y-1/2 right-0 text-5xl cursor-pointer z-50"
            onClick={clickHandler}
          ></div>
        )}
      >
        {StaticData.map((item, index) => (
          <div
            className="w-[80%] md:w-[90%] h-full max-h-[500px] mx-auto"
            key={index}
          >
            <img
              className="w-full h-full object-cover"
              src={item.imgPath}
              alt=""
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
